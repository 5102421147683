<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DataViewer from "../../../components/elements/DataViewer.vue";
/**
 * Typography component
 */
export default {
  page: {
    title: "Consultant",
    meta: [{ name: "Consultant", content: appConfig.description }],
  },
  components: { DataViewer, Layout, PageHeader },
  data() {
    return {
      title: "Consultant",
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: "consultant",
        },
        {
          text: "consultant Info",
          active: true,
        },
      ],
      data: [
        { key: "Title", value: "lorem" },
        { key: "Meeting URL", type: "link", value: "httpasdasdasdad/asdads" },
        { key: "Phone Number", value: "lorem" },
        {
          key: "Address",
          value: "lorem lorem",
        },
        { key: "Number of Answerd Questions", value: 20 },
        { key: "Number of Qonsultations", value: 10 },
        { key: "Number of Contracts", value: 5 },
      ],

      edit: false,
      form: { name: "", Phone: "", address: "", password: "" },
    };
  },
  methods: {
    editClicked(item) {
      console.log(item);
      const index = this.tableData.findIndex((el) => el.id === item.item.id);
      console.log(index);
      this.editData.Type = this.tableData[index].display_name;

      this.$bvModal.show("modal-edit");
    },
    add() {
      this.$bvModal.show("modal-add");
    },
  },
};
</script>

<template>
  <Layout
    ><PageHeader :title="title" :items="items" />
    <DataViewer :title="'Profile'" :data="data" />
    <div class="row">
      <div class="col-6">
        <div class="card">
          <b-button v-b-modal.modal-backdrop variant="warning">
            Edit Consultation
          </b-button>
          <b-modal
            no-close-on-backdrop
            id="modal-backdrop"
            title="Edit Consultant"
            centered
            title-class="font-18"
          >
            <label for="date-time-start">Start From</label>
            <b-form-input
              id="date-time-start"
              value="2019-08-19T13:45:00"
              type="datetime-local"
            ></b-form-input>
            <label for="date-time-end">End in</label>
            <b-form-input
              id="date-time-end"
              value="2019-08-19T13:45:00"
              type="datetime-local"
            ></b-form-input>
          </b-modal>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <b-button v-b-modal.modal-backdrop-cancel variant="danger">
            Cancel Consultation
          </b-button>
          <b-modal
            no-close-on-backdrop
            id="modal-backdrop-cancel"
            title="Cancel Consultant"
            centered
            title-class="font-18"
          >
            Are you sure Want to Cancel this Consultant
          </b-modal>
        </div>
      </div>
    </div>
  </Layout>
</template>
